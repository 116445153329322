$(document).ready(function(){

    //modal
    (function(){
        $('.free_pop, .product__link, .price__link').click(function(e) {
            e.preventDefault();

            $('.modal-window').addClass('open');
            $('body').css('overflow', 'hidden');

        });

        $('.modal__close').click(function(e) {
            e.preventDefault();

            $('.modal-window').removeClass('open');
            $('body').css('overflow', 'visible');

        });

        $('.modal-window').click(function (e) {

            if ($(e.target).has('.modal__block').length != 0) {
                console.log(123);
                $('.modal-window').removeClass('open');
                $('body').css('overflow', 'visible');
            }
        });
    })();
    //graph
    (function(){
        var graphs = $('.graph'),
            wHeight = window.innerHeight;

        $(window).scroll(function(e) {
            var wScroll = window.pageYOffset;


            graphs.each(function() {
               var $this = $(this),
                   graphTop = $this.offset().top;

                if ($this.hasClass('active')) return;

                if ((graphTop - wScroll - (wHeight/2)) <= 0) {
                    console.log(123);
                    $this.addClass('active');
                }

            });

        });
    })();
    //sectionScroll
    (function(){

        $('.navigation__item').click(function(e){

            e.preventDefault();

            var el = $(this).attr('href');

            if ($(e.target).hasClass('policy')) {
                return;
            }

            $("html, body").animate({
                scrollTop: $(el).offset().top - 100}, 1000);
        });
    })();
    //slider
    (function () {
        $('.slider').owlCarousel({
            margin: 20,
            items: 3,
            loop: true,
            responsive: {
                0: {
                    items: 1
                },

                700 : {
                    items: 3
                }
            }
        });

        $('.slider__next').click(function() {
            $('.slider').trigger('next.owl.carousel');
        });
    })();
    //fixedHeader
    (function(){
        var a = document.querySelector('.header'), b = null, P = -10;  // если ноль заменить на число, то блок будет прилипать до того, как верхний край окна браузера дойдёт до верхнего края элемента. Может быть отрицательным числом
        window.addEventListener('scroll', Ascroll, false);
        document.body.addEventListener('scroll', Ascroll, false);

        function Ascroll() {
            if (b == null) {

                var Sa = getComputedStyle(a, ''), s = '';
                for (var i = 0; i < Sa.length; i++) {
                    if (Sa[i].indexOf('overflow') == 0 || Sa[i].indexOf('padding') == 0 || Sa[i].indexOf('border') == 0 || Sa[i].indexOf('outline') == 0) {
                        s += Sa[i] + ': ' +Sa.getPropertyValue(Sa[i]) + '; '
                    }
                }

                b = document.createElement('div');
                b.style.cssText = s + ' box-sizing: border-box; width: ' + a.offsetWidth + 'px;';
                a.insertBefore(b, a.firstChild);
                var l = a.childNodes.length;
                for (var i = 1; i < l; i++) {
                    b.appendChild(a.childNodes[1]);
                }
                a.style.height = b.getBoundingClientRect().height + 'px';
                a.style.padding = '0';
                a.style.border = '0';
                a.style.paddingTop = '0';
            }
            var Ra = a.getBoundingClientRect(),
                R = Math.round(Ra.top + b.getBoundingClientRect().height - document.querySelector('.footer').getBoundingClientRect().top + 0);  // селектор блока, при достижении верхнего края которого нужно открепить прилипающий элемент;  Math.round() только для IE; если ноль заменить на число, то блок будет прилипать до того, как нижний край элемента дойдёт до футера
            if ((Ra.top - P) <= 0) {
                if ((Ra.top - P) <= R) {
                    b.className = 'stop';
                    b.style.top = - R +'px';
                } else {
                    b.className = 'sticky';
                    b.style.top = 0 + 'px';
                }

            } else {
                b.className = '';
                b.style.top = '';
            }
            window.addEventListener('resize', function() {
                a.children[0].style.width = getComputedStyle(a, '').width
            }, false);
        }
    })();
    //request
    (function () {

        var isSend = false;

        $('body').off('submit').on('submit', 'form', function(e) {

            e.preventDefault();

            var $this = $(this),
                wrapper = $this.find('.wrap-inputs'),
                inputs = $this.find('input'),
                successSuper = wrapper.siblings('.form-success');

            if (isFill(inputs) && !isSend) {
                isSend = true;

                // $.ajax({
                //
                //     type: "POST",
                //     url: '/sender.php',
                //     data: $this.serialize()
                //
                //
                // });

                ready(wrapper, inputs, successSuper);

            }


        });

        function isFill(selector) {
            var flag = true;

            selector.each(function () {
                if ($(this).val().length == 0 && $(this).css('display') !== 'none') {
                    flag = false;
                    $(this).css('border-color', '#ff0000');
                    isSend = false;
                } else {
                    $(this).attr('style', '');
                }
            });

            return flag;

        }

        function ready(wrapper, inputs, successSuper) {

            // console.log(wrapper);

            wrapper.animate({

                'opacity': '0'

            },400, function(){

                successSuper.fadeIn(400, function(){

                    inputs.each(function(){
                        $(this).val('');
                    });

                    setTimeout(function(){
                        successSuper.fadeOut(400, function(){

                            wrapper.animate({
                                'opacity': '1'
                            }, 400, function(){
                                isSend = false;
                            })

                        });

                    },1000)

                })

            });

        }

    })();
    //inputmask
    (function() {

        $('input[type=tel]').inputmask({
            "mask": "+7 (999) 999-99-99",
            'showMaskOnHover': false,
            'showMaskOnFocus': true
        });
    })();
});
